
import ethereum from "../assets/images/ethereum.svg";
import basenetwork from "../assets/images/basenetwork.svg";
import bnbchain from "../assets/images/bnbchain.svg";
import toncoin from "../assets/images/toncoin.svg";
import tron from "../assets/images/tron.svg";
import solana from "../assets/images/solana.svg";

export const voteOptions = [
    {
        icon: ethereum,
        value: "ethereum",
        label: "Ethereum",
    },
    {
        icon: basenetwork,
        value: "base",
        label: "Base",
    },
    {
        icon: bnbchain,
        value: "bnbchain",
        label: "BNB Chain",
    },
    // {
    //     icon: toncoin,
    //     value: "ton",
    //     label: "TON",
    // },
    {
        icon: tron,
        value: "tron",
        label: "TRON",
    },
    {
        icon: solana,
        value: "solana",
        label: "Solana",
    },
]