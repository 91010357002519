import { PublicKey } from "@solana/web3.js";

export const isValidSolAddress = (address: string): boolean => {
    try {
        const key = new PublicKey(address);
        return PublicKey.isOnCurve(key.toBytes());
    } catch (err) {
        return false;
    }
}

export const isValidTronAddress = async (address: string): Promise<boolean> => {
    try {
        const options = {
            method: 'POST',
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            body: JSON.stringify({ address, visible: true })
        };

        const response = await fetch('https://api.shasta.trongrid.io/wallet/validateaddress', options)
            .then(response => response.json())
        // .then(response => console.log(response))
        // .catch(err => console.error(err));

        return response.result;
    } catch (err) {
        return false;
    }
}

export const isValidTonAddress = async (address: string): Promise<boolean> => {
    try {
        const response = await fetch(`https://testnet.toncenter.com/api/v2/getAddressInformation?address=${address}`)
            .then(response => response.json())
        // .then(response => console.log(response))
        // .catch(err => console.error(err));

        return response.ok;
    } catch (err) {
        return false;
    }
}