import { mainnet, sepolia } from 'wagmi/chains'
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";

const projectId = process.env.REACT_APP_WC_PROJECT_ID as string;

const metadata = {
    name: "Wallet Collection",
    description: "",
    url: "",
    icons: ["https://avatars.mywebsite.com/"]
};

export const config = defaultWagmiConfig({
    chains: process.env.REACT_APP_ENV === "production" ? [mainnet] : [sepolia],
    projectId,
    metadata
});

createWeb3Modal({
    wagmiConfig: config,
    projectId
});