import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styles from "../../styles/Airdrop/Form.module.scss";
import { useAccount, useSignMessage } from "wagmi";
import { useGetWallet, useSubmitWallet } from "../../hooks/airdrop.hooks";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ethereum from "../../assets/images/ethereum.svg";
import toncoin from "../../assets/images/toncoin.svg";
import tron from "../../assets/images/tron.svg";
import solana from "../../assets/images/solana.svg";
import { isValidSolAddress, isValidTonAddress, isValidTronAddress } from "../../utils";
import { AxiosError } from "axios";
import { Toggle } from "./Toggle";
import Select, { components, OptionProps } from "react-select";
import { voteOptions } from "../../configs/vote.config";

const { Option } = components;
const IconOption = (props: OptionProps) => (
    <Option {...props} className={styles.dropdownOption}>
        <img
            src={(props?.data as any).icon}
            alt={(props?.data as any).label}
        />
        {(props?.data as any).label}
    </Option>
);

export default function Form(): JSX.Element {
    const [step, setStep] = useState(0);
    const [solAddress, setSolAddress] = useState<string | "">("");
    // const [tonAddress, setTonAddress] = useState<string | "">("");
    const [tronAddress, setTronAddress] = useState<string | "">("");
    const [isValidSol, setIsValidSol] = useState(true);
    // const [isValidTon, setIsValidTon] = useState(true);
    const [isValidTron, setIsValidTron] = useState(true);
    const { isConnected, address, isConnecting } = useAccount();
    const { open } = useWeb3Modal();
    const { signMessageAsync, isPending: isSignaturePending } = useSignMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [vote, setVote] = useState<typeof voteOptions[number]>(voteOptions[0]);

    const {
        mutate: submitWallet,
        data: response,
        isPending: isSubmitPending,
        isError: isSubmitError,
        isSuccess: isSubmitSuccess,
        error
    } = useSubmitWallet();

    const {
        mutate: getWallet,
        data: walletData,
        isPending: isGetPending,
        isError: isGetError,
        isSuccess: isGetSuccess
    } = useGetWallet();

    // Common Loader
    useEffect(() => {
        setIsLoading(isSignaturePending || isSubmitPending || isValidating || isGetPending);
    }, [isSignaturePending, isSubmitPending, isValidating, isGetPending]);

    // Response Effect
    useEffect(() => {
        if (isSubmitError) {
            const _error = error as AxiosError;
            if (_error.response?.data)
                toast.error(_error.response?.data as string);
            else
                toast.error("Failed to submit wallet!");
        }
        if (isSubmitSuccess) {
            toast.success("Message signed and sent successfully!");
            setStep(1);
        }
    }, [isSubmitError, isSubmitSuccess]);

    const validateAddresses = async () => {
        try {
            setIsValidating(true);
            setIsValidSol(true);
            // setIsValidTon(true);
            setIsValidTron(true);

            await new Promise(resolve => setTimeout(resolve, 500));

            const _isValidSolAddress = isValidSolAddress(solAddress);
            setIsValidSol(_isValidSolAddress);
            // const _isValidTonAddress = await isValidTonAddress(tonAddress);
            // setIsValidTon(_isValidTonAddress);
            const _isValidTronAddress = await isValidTronAddress(tronAddress);
            setIsValidTron(_isValidTronAddress);


            if (_isValidSolAddress
                // && _isValidTonAddress
                && _isValidTronAddress) {
                return true;
            }
            else {
                toast.error("Invalid address");
                return false;
            }
        } catch (err) {
            console.error("Failed to validate addresses: ", err);
            toast.error("Failed to validate addresses");
            return false;
        } finally {
            setIsValidating(false);
        }
    }

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();

            const isValid = await validateAddresses();
            if (!isValid) return;

            const message = `Solana Wallet Address: ${solAddress}, Tron Address: ${tronAddress}, Vote: ${vote.value}, Timestamp: ${Date.now()}, Time: ${Date()}`;
            const signature = await signMessageAsync({ message });
            submitWallet({ message, signature });
        } catch (err) {
            console.error("Failed to submit: ", err);
            toast.error("Failed to submit!");
        }
    };

    const handleToggle = async (isNew: boolean) => {
        setIsUpdate(!isNew);
        console.log("isUpdate?", !isNew);
    }

    const handleGetWallet = async () => {
        try {
            const message = `Timestamp: ${Date.now()}`;
            const signature = await signMessageAsync({ message });
            getWallet({ message, signature });
        } catch (err) {
            console.error("Failed to get wallet: ", err);
            toast.error("Failed to get wallet entry!");
        }
    };

    const handleVoteSelection = (selection: typeof voteOptions[number]) => {
        setVote(selection);
    }

    useEffect(() => {
        setSolAddress("");
        // setTonAddress("");
        setTronAddress("");
    }, [isUpdate]);

    useEffect(() => {
        if (walletData && walletData.data) {
            setSolAddress(walletData.data.solAddress);
            setTronAddress(walletData.data.tronAddress);
            // setTonAddress(walletData.data.tonAddress);
            setVote(voteOptions.find((vote) => vote.value === walletData.data.vote) || voteOptions[0]);
        }
    }, [walletData]);

    if (step == 0)
        return (
            <form className={styles.form} onSubmit={handleSubmit}>
                <Toggle onChange={handleToggle} />
                {(isConnected && isUpdate) &&
                    <button
                        onClick={handleGetWallet}
                        className={styles.secondary__button}
                        disabled={isLoading}
                    >
                        Request Details
                    </button>
                }
                <div className={styles.input__box}>
                    <div className={styles.label}>
                        <img src={ethereum} alt="Ethereum" />
                        <span>ETH/BASE/BSC Address</span>
                    </div>
                    <input
                        type="text"
                        name="address"
                        id="address"
                        value={address || ""}
                        placeholder="Connect Wallet"
                        required
                        disabled
                    />
                </div>
                <div className={`${styles.input__box} ${isValidSol ? "" : styles.invalidAddress}`}>
                    <div className={styles.label}>
                        <img src={solana} alt="Solana" />
                        <span>Insert Solana Address</span>
                    </div>
                    <input
                        type="text"
                        name="solAddress"
                        id="solAddress"
                        onChange={(e) => setSolAddress(e.target.value)}
                        value={solAddress || ""}
                        placeholder="Solana Wallet Address"
                        required
                        disabled={isLoading}
                    />
                </div>
                {/* <div className={`${styles.input__box} ${isValidTon ? "" : styles.invalidAddress}`}>
                    <div className={styles.label}>
                        <img src={toncoin} alt="Toncoin" />
                        <span>Insert TON Address</span>
                    </div>
                    <input
                        type="text"
                        name="tonAddress"
                        id="tonAddress"
                        onChange={(e) => setTonAddress(e.target.value)}
                        value={tonAddress || ""}
                        placeholder="TON Wallet Address"
                        required
                        disabled={isLoading}
                    />
                </div> */}
                <div className={`${styles.input__box} ${isValidTron ? "" : styles.invalidAddress}`}>
                    <div className={styles.label}>
                        <img src={tron} alt="Tron" />
                        <span>Insert Tron Address</span>
                    </div>
                    <input
                        type="text"
                        name="tronAddress"
                        id="tronAddress"
                        onChange={(e) => setTronAddress(e.target.value)}
                        value={tronAddress || ""}
                        placeholder="TRON Wallet Address"
                        required
                        disabled={isLoading}
                    />
                </div>
                <div className={styles.voteContainer}>
                    <span>Favourite chain: </span>
                    <Select
                        value={vote}
                        options={voteOptions}
                        className={styles.voteSelect}
                        // @ts-ignore
                        components={{ Option: IconOption }}
                        // @ts-ignore
                        onChange={handleVoteSelection}
                    />
                </div>
                <div className={styles.actionContainer}>
                    {isConnected
                        ? <button
                            type="submit"
                            className={styles.submit__button}
                            disabled={isLoading}
                        >
                            Submit
                        </button>
                        : <button
                            onClick={(e) => { e.preventDefault(); open() }}
                            className={styles.submit__button}
                        >
                            Connect Wallet
                        </button>}
                </div>
            </form>
        );
    else
        return (
            <div className={styles.success}>
                <iframe src="https://lottie.host/embed/289ea61c-0263-4cd4-a125-9abb0ad1daa9/vFpjJtqEqP.json"></iframe>
                <span>COMPLETE</span>
            </div>
        );

}
