export const faqs = [
    {
        question: "How to",
        answer: "<b>Step 1:</b> Connect Presale Wallet <br/> <b>Step 2:</b> Insert your wallet addresses for each chain <br/> <b>Step 3:</b> Vote for your preferred chain <br/> <b>Step 4:</b>: Submit",
        disabled: false
    },
    // {
    //     question: "Eligibility",
    //     answer: "The original presale was for a memecoin, and zkGUN was only meant to be a suprise bonus token provided to presalers of the memecoin. <br/><br/> Therefore, the team has decided that if you have profited from zkGUN (your net profit was greater than your total contribution), you will not be eligible for the relaunch of zkGUN or the memecoin. We want to prioritize those who held and did not sell in profit. <br/><br/> As long as you are still holding your zkGUN tokens, you will be eligible for the relaunch of $ZKGUN and will also receive memecoin allocation. <br/><br/> Eligible holders will receive the same amount of tokens they had remaining after the snapshot was taken.",
    //     disabled: false
    // },
    // {
    //     question: "Snapshot Details",
    //     answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tempus ex at nibh suscipit tristique. Proin in turpis sed urna tempus accumsan a vitae tortor. Integer posuere lacinia enim, sit amet luctus sapien molestie venenatis",
    //     disabled: true
    // },
]