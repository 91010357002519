import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./styles/global.scss";
import styles from "./styles/Pages/Home.module.scss";
import { SideNav } from "./components/SideNav";
import Airdrop from "./pages/Airdrop";

function App() {
    return (
        <>
            <SideNav />
            <div className={styles.routes}>
                <Routes>
                    <Route path="/" element={<Airdrop />} />
                </Routes>
            </div>
            <ToastContainer theme="dark" position="bottom-right" />
        </>
    );
}

export default App;
