import styles from "../styles/FAQ.module.scss";

export default function FAQ({ faq, index, toggleFAQ }:
    {
        faq: {
            open: boolean,
            question: string,
            answer: string,
            disabled: boolean
        },
        index: number,
        toggleFAQ: (index: number) => void
    }): JSX.Element {
    return (
        <div
            className={`${styles.faq} ${faq.disabled ? styles.disabled : ""} ${faq.open ? styles.open : ""}`}
            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className={styles.faqQuestion}>{faq.question}</div>
            <div dangerouslySetInnerHTML={{ __html: faq.answer }} className={styles.faqAnswer} />
        </div>
    );
};
