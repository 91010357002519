import React from 'react';
import styles from '../../styles/Airdrop/Toggle.module.scss';

type ToggleProps = {
    onChange: (isNew: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({ onChange }) => {

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        onChange(!isChecked);
    }

    return (
        <div className={styles.main}>
            <div className={styles.toggleSwitch}>
                <input
                    type="checkbox"
                    className={styles.checkbox}
                    name={'toggle'}
                    id={'toggleSwitch'}
                    onChange={handleToggle} />
                <label className={styles.label} htmlFor={'toggleSwitch'}>
                    <span className={styles.toggleInner} />
                    <span className={styles.switch} />
                </label>
            </div>
        </div>
    )
}