import styles from "../styles/Sidebar.module.scss";
import swap from "../assets/images/swap.svg";
import claim from "../assets/images/claim.svg";
import stake from "../assets/images/stake.svg";
import messaging from "../assets/images/messaging.svg";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";
import document from "../assets/images/document.svg";
import hamburger_icon from "../assets/images/hamburger_icon.png";
import wallet from "../assets/images/wallet.svg";
import pair_analytics from "../assets/images/pair_analytics.svg";
import trade_analytics from "../assets/images/trade_analytics.svg";
import ai_token_scouter from "../assets/images/ai_token_scouter.svg";
import airdrop from "../assets/images/airdrop.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

export const SideNav = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const pathName = location.pathname;

    const swapPage = pathName.includes("swap");
    const airdropPage = pathName.includes("airdrop");
    const stakePage = pathName.includes("stake");
    const claimPage = pathName.includes("claim");

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.main}>
            <div className={styles.mobileNav}>
                <div className={`${styles.logoContainer}`}>
                    <a href="/" className={styles.logoLink}>
                        <img src={airdrop} className={styles.logoImage} alt="airdrop" />
                        WALLET SUBMISSION
                        {/* LOGO HERE */}
                    </a>
                </div>
                <button className={styles.mobileMenu} onClick={toggleMenu}>
                    {!isMenuOpen ? (
                        <img src={hamburger_icon} alt="menu-icon" />
                    ) : (
                        "X"
                    )}
                </button>
            </div>
            <div className={`${styles.mobileOpenOverlay} ${isMenuOpen ? styles.mobileOpen : ""}`} onClick={toggleMenu} />
            <div className={`${styles.sidebar} ${isMenuOpen ? styles.mobileOpen : ""}`}>
                <div className={styles.firstRow}>
                    {/* COMPACT LOGO HERE */}
                    {/* FULL LOGO IN DIV */}
                    <div
                        className={`${styles.sideContentWrapper} ${(airdropPage || pathName === "/") && styles.active}`}
                        role="button"
                        onClick={() => { navigate("/"); toggleMenu(); }}>
                        <img src={airdrop} className={styles.sideIcon} alt="airdrop" />
                        <span>Wallet Submission</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${swapPage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/swap"); toggleMenu(); }}>
                        <img src={swap} className={styles.sideIcon} alt="swap" />
                        <span>Swap (CCIP)</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${claimPage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/claim"); toggleMenu(); }}>
                        <img src={claim} className={styles.sideIcon} alt="claim" />
                        <span>Claim</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/stake"); toggleMenu(); }}>
                        <img src={stake} className={styles.sideIcon} alt="stake" />
                        <span>Stake</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/wallet"); toggleMenu(); }}>
                        <img src={wallet} className={styles.sideIcon} alt="wallet" />
                        <span>Wallet</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/pair-analytics"); toggleMenu(); }}>
                        <img src={pair_analytics} className={styles.sideIcon} alt="pair analytics" />
                        <span>Pair Analytics</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/trade-analytics"); toggleMenu(); }}>
                        <img src={trade_analytics} className={styles.sideIcon} alt="trade analytics" />
                        <span>Trade Analytics</span>
                    </div>
                    <div
                        className={`${styles.sideContentWrapper} ${stakePage && styles.active} ${styles.comingSoon}`}
                        role="button"
                        onClick={() => { navigate("/ai-token-scouter"); toggleMenu(); }}>
                        <img src={ai_token_scouter} className={styles.sideIcon} alt="ai token scouter" />
                        <span>AI Token Scouter</span>
                    </div>
                </div>
                <div className={styles.secondRow}>
                    <div className={`${styles.sideContentWrapper} ${styles.comingSoon}`} onClick={() => { window.open('https://t.me/bot', '_blank'); }}>
                        <img src={messaging} className={styles.sideIcon} alt="messaging" />
                        <span>TG Bot</span>
                    </div>
                    <div className={`${styles.sideContentWrapper} ${styles.comingSoon}`} onClick={() => { window.open('https://t.me/project', '_blank'); }}>
                        <img src={telegram} className={styles.sideIcon} alt="telegram" />
                        <span>Commmunity</span>
                    </div>

                    <div className={`${styles.sideContentWrapper} ${styles.comingSoon}`} onClick={() => { window.open('https://x.com/project', '_blank'); }}>
                        <img src={x} className={styles.sideIcon} alt="twitter" />
                        <span>Twitter</span>
                    </div>

                    <div className={`${styles.sideContentWrapper} ${styles.comingSoon}`} onClick={() => { window.open('https://docs..org', '_blank'); }}>
                        <img src={document} className={styles.sideIcon} alt="document" />
                        <span>Docs</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
