import { useState } from "react";
import Form from "../components/Airdrop/Form";
import FAQ from "../components/FAQ";
import { faqs as faqConfig } from "../configs/faq";
import styles from "../styles/Pages/Airdrop.module.scss";

type TFAQ = {
    question: string;
    answer: string;
    open: boolean;
    disabled: boolean;
};

const Airdrop = () => {
    const [faqs, setFaqs] = useState<TFAQ[]>(
        faqConfig.map((faq) => ({
            ...faq,
            open: false,
        }))
    );

    const toggleFAQ = (index: number) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <section className={styles.airdrop}>
            <div className={styles.form}>
                <div className={styles.form__bg_1}>
                    <div className={styles.form__bg_2}>
                        <div className={styles.form__bg_3}>
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.faqs}>
                {faqs.map((faq, index) => (
                    <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
            </div>
        </section>
    );
};

export default Airdrop;
