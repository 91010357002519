import axios from "axios";
import { SubmitWalletRequest } from "../types";
import { GetWalletRequest } from "../types/api.types";

const airdropApi = axios.create({
    baseURL: process.env.REACT_APP_AIRDROP_API_URL || "http://localhost:5000",
});

// Airdrop API
export const submitWallet = async (data: SubmitWalletRequest) => airdropApi.post("/wallets", data);
export const getWallet = async (data: GetWalletRequest) => airdropApi.post("/wallets/request", data);

export default airdropApi;
